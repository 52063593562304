import { Layout, Row } from 'antd';
import React from 'react';
import { RoutesRenderer } from '../MainContent';
import ILayout from '../../../Core/Models/ILayout';

const { Content } = Layout;

interface Props {
    children?: React.ReactNode;
    layout: React.ComponentType<ILayout>;
}

const MainContent: React.FC<Props> = ({ children, layout: LayoutComponent }) => {
    return (
        <Content>
            <Row align="middle" justify="center">
                <LayoutComponent>{children}</LayoutComponent>
            </Row>
        </Content>
    );
};

export default MainContent;
