import React from 'react';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import ConfigService from 'Core/ConfigService';
import AuthUser from 'Core/Models/AuthUser';
import moment from 'moment';

export default class TokenService {
    /**
     * Get user data from the token stored in cookies.
     * @returns AuthUser | undefined
     */
    public static GetUserFromToken(): AuthUser | undefined {
        try {
            const config = ConfigService.getSync();
            const tokenString = Cookies.get(config.Security.tokenName);

            if (!tokenString) {
                console.warn("Token not found in cookies.");
                return undefined;
            }

            const token = jwtDecode<{ [key: string]: any }>(tokenString);
            if (!token) {
                console.warn("Failed to decode token.");
                return undefined;
            }


            if (this.isTokenExpired(token, config.Security.tokenProperties.expirationDate)) {
                console.warn("Token has expired.");
                return undefined;
            }

            return this.mapTokenToAuthUser(token, config);
        } catch (error) {
            console.error("Error in GetUserFromToken:", error);
            return undefined;
        }
    }

    /**
     * Check if the token is expired.
     * @param token - Decoded token.
     * @param expirationProperty - Property in the token indicating expiration.
     * @returns boolean
     */
    private static isTokenExpired(token: { [key: string]: any }, expirationProperty: string): boolean {
        const expirationTime = token[expirationProperty];
        if (!expirationTime) return false; // Assume token without expiration is valid.

        const expirationMoment = moment.unix(expirationTime);
        return expirationMoment.isBefore(moment());
    }

    /**
     * Map decoded token to AuthUser object.
     * @param token - Decoded token.
     * @param config - Application configuration.
     * @returns AuthUser
     */
    private static mapTokenToAuthUser(token: { [key: string]: any }, config: any): AuthUser {
        const getArrayFromStringOrValue = (value: any): string[] =>
            Array.isArray(value) ? value : value ? [value] : [];

        return {
            id: token[config.Security.tokenProperties.id],
            username: token[config.Security.tokenProperties.username],
            firstName: token[config.Security.tokenProperties.firstName],
            lastName: token[config.Security.tokenProperties.lastName],
            email: token[config.Security.tokenProperties.email],
            sessionId: token[config.Security.tokenProperties.sessionId],
            expirationTime: token[config.Security.tokenProperties.expirationDate]
                ? moment.unix(token[config.Security.tokenProperties.expirationDate])
                : undefined,
            fcmToken: token[config.Security.tokenProperties.fcmtoken],
            isGuest: token[config.Security.tokenProperties.isguest] === 'true',
            isHighAdmin: token[config.Security.tokenProperties.ishighadmin] === 'true',
            operations: getArrayFromStringOrValue(token[config.Security.tokenProperties.operation]),
            roles: getArrayFromStringOrValue(token[config.Security.tokenProperties.role]),
        };
    }
}
