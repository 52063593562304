import * as React from 'react';
import { Row, Col } from 'antd';
import { MainLogo, SLanding } from '../../Components/Layout/LandingLayout/LandingLayout.Styled';
import mainLogo from '../../Assests/images/sasudokuvs.png';
import Dashboard from '../../Components/Content/Dashboard';

export interface Props {
    children?: React.ReactNode;
}

const DashboardPage: React.FC<Props> = ({ children }) => {
    return (
        <Dashboard />
    );
};

export default DashboardPage;
