import { Layout } from "antd";
import Sider from "antd/es/layout/Sider";
import styled from "styled-components";

export const MainLogo = styled.img`
  max-height: 50px; /* Adjust for the header height */
  max-width: 100%; /* Prevent overflow */
  object-fit: contain;
  display: block;
  margin: 0 auto; /* Center horizontally */
`;

export const StyledSider = styled(Sider)`
        overflow: auto;
        height: 100vh;
        inset-inline-start: 0;
        top: 0;
        bottom: 0;
        scrollbar-width: thin;
        scrollbar-gutter: stable;
    `;


export const SiderPageSection = styled(Layout)`
    overflow-y: auto;
`;

export const HeaderPageSection = styled(Layout)`
    overflow-y: auto;

`;

export const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    max-heigh: 100vh;
    overflow: hidden;
`;