// AppRoutes.ts
import React from 'react';
import { AppRoute } from './Models/AppRoute';
import EmptyLayout from '../Components/Layout/EmptyLayout';
import LandingLayout from '../Components/Layout/LandingLayout';
import Landing from '../Pages/Landing';
import DashboardPage from '../Pages/Dashboard';
import RequestAccountDeletion from '../Pages/RequestAccountDeletetion';
import MainContent from '../Components/Layout/MainContent';
import Login from '../Components/Content/Login';
import LoginLayout from '../Components/Layout/LoginLayout';
import Dashboard from '../Components/Content/Dashboard';
import AdminLayout from '../Components/Layout/AdminLayout';
import {
    AppstoreOutlined,
    TeamOutlined,
    NotificationOutlined,
} from "@ant-design/icons";
import Logout from '../Components/Content/Logout';

const AppRoutes: AppRoute[] = [
    {
        path: '/',
        element: <Landing />,
        layout: LandingLayout,
        hideInMainMenu: true,
    },
    {
        path: '/RequestAccountDeletion',
        element: <RequestAccountDeletion />,
        hideInMainMenu: true,
        layout: LandingLayout,
    },
    {
        path: '/Login',
        element: <Login />,
        layout: LoginLayout,
        //requiredOperations: ['Access Dashboard'],
        hideInMainMenu: true,
        title: 'Login',
    },
    {
        path: '/Admin',
        skip: true,
        redirect: 'Dashboard',
        icon: <AppstoreOutlined />,
        title: 'Admin',
        routes: [
            {
                path: 'Dashboard',
                element: <DashboardPage />,
                layout: AdminLayout,
                requiredOperations: ['Access Dashboard'],
                hideInMainMenu: false,
                title: 'Dashboard',
                icon: <AppstoreOutlined />,
            },
        ]
    },
    {
        path: '/Logout',
        element: <Logout />,
        layout: LoginLayout,
        hideInMainMenu: true,
        title: 'Logout',
    },

];

export { AppRoutes };
