import React, { useContext } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { AppRoutes } from '../../../../Core/AppRoutes';
import AuthContext from '../../../../Context/AuthContext';
import { AppRoute, LayoutType } from '../../../../Core/Models/AppRoute';
import Utility from '../../../../Core/Utility';
import AuthUser from 'Core/Models/AuthUser';

export interface Props {
    defaultLayout: LayoutType;
    isDefault?: boolean;
}

const RoutesRenderer: React.FC<Props> = ({ defaultLayout }) => {
    const wrapInLayout = (
        route: AppRoute,
        fullPath: string,
        CurrentLayout: LayoutType,
        requiredOperations: string[] = []
    ): React.ReactNode => {
        const { data: user } = useContext(AuthContext) as { data: AuthUser };

        const requiredOperationsUsed = route.skipParentRequiredOperations
            ? route.requiredOperations
            : requiredOperations;

        const hasPermission = Utility.HasAnyOperations(user, requiredOperationsUsed || []);

        return (
            <Route
                path={fullPath}
                key={fullPath}
                element={
                    hasPermission ? (
                        <CurrentLayout>
                            {/* Ensure the layout renders nested routes here */}
                            {route.redirect ? (
                                <Navigate to={route.redirect} replace />
                            ) : (
                                route.element // Render the route element
                            )}
                        </CurrentLayout>
                    ) : (
                        <Navigate to="/" replace /> // Redirect if no permission
                    )
                }
            />
        );
    };

    const renderRoute = (
        route: AppRoute,
        parentPath: string | undefined = '',
        parentLayout: LayoutType,
        parentOperationsRequired: string[] | undefined = []
    ): React.ReactElement | null => {
        const fullPath = Utility.combinePath(parentPath, route.path as string);
        const currentLayout = route.layout || parentLayout;
        const effectiveRequiredOperations = MergeJoin(parentOperationsRequired, route);

        return (
            <React.Fragment key={fullPath}>
                {wrapInLayout(route, fullPath, currentLayout, effectiveRequiredOperations)}
                {route.routes &&
                    route.routes.map(childRoute =>
                        renderRoute(childRoute, fullPath, currentLayout, effectiveRequiredOperations)
                    )}
            </React.Fragment>
        );
    };

    const MergeJoin = (parentOperationsRequired: string[], route: AppRoute): string[] => {
        const allOperations = [...parentOperationsRequired, ...(route.requiredOperations ?? [])];
        return Array.from(new Set(allOperations)); // Removes duplicates
    };

    return (
        <Routes>
            {AppRoutes.map(route =>
                renderRoute(route, undefined, defaultLayout)
            )}
        </Routes>
    );
};

export default RoutesRenderer;
