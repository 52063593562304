import React from 'react';
import { SwaggerException } from '../Api/Api';

import isEqual from 'lodash/isEqual';
import startCase from 'lodash/startCase';
import AuthUser from 'Core/Models/AuthUser';
import { matchPath } from 'react-router';
import { AppRoute } from 'Core/Models/AppRoute';
import { Moment } from 'moment';
import { Tooltip } from 'antd';

export default class Utility {
    constructor() { }

    static renderDateWithReference(createDate?: Moment, dateFormat?: string): React.ReactNode {
        return (
            <Tooltip title={createDate?.fromNow()}>
                <span>{createDate?.format(dateFormat)}</span>
            </Tooltip>
        );
    }

    static convertToFullPath(routes?: AppRoute[], basePath: string = ''): AppRoute[] | undefined {
        return routes?.map(x => {
            const path = this.combinePath(basePath, x.path as string);
            return { ...x, path, routes: this.convertToFullPath(x.routes, path) };
        });
    }
    public static combinePath(...args: string[]) {
        let path = args[0];

        if (!args[0]) {
            return args[1] || args[0];
        }

        return (
            args
                .reduce((previousPath: string, nextpath: string) => {
                    return nextpath.indexOf('/') === 0 && nextpath === '/'
                        ? previousPath
                        : previousPath + '/' + nextpath;
                }, '')
                // this is not an ideal solutions but it should work for now
                .replace('//', '/')
        );
    }

    public static isPathOrSubpathAMatchV5(path: string, match: AppRoute): boolean {
        return (
            !!matchPath(path, match.title) ||
            (match.routes?.some(x => Utility.isPathOrSubpathAMatchV5(path, x)) ?? false)
        );
    }

    public static isPathOrSubpathAMatch(
        fullPath: string,
        currentPath: string
    ): boolean {
        return !!matchPath(fullPath, currentPath); // Corrected order
    }
    public static urlPathAndSearch(fullUrl?: string) {
        if (!fullUrl) return undefined;
        let url = new URL(fullUrl);
        return url.pathname + url.search;
    }

    public static downloadURI(uri, name) {
        let link = document.createElement('a');
        link.download = name;
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    public static GetMatchingRoute(path: string, match: AppRoute): AppRoute | undefined {
        if (!!matchPath(path, match.title)) {
            return match;
        } else {
            match.routes?.filter(x => Utility.GetMatchingRoute(path, x))?.pop();
        }
    }

    public static GetYears() {
        var years = [2019, 2022, 2023, 2024];
        return years;
    }

    public static HasOperations(user?: AuthUser, requiredOperations?: string[]) {
        // if no operations are supplied, it is a public page
        if (!requiredOperations || requiredOperations.length === 0) {
            return true;
        }

        // if operations are supplied and the user is not logged in, they need to login
        if ((user === null || user === undefined) && requiredOperations.length > 0) {
            return false;
        }

        // search through all required operations to see if the user has all of them

        for (const requiredOperation of requiredOperations) {
            if (user && user.operations && user.operations.length > 0) {
                const foundOperation = user.operations.filter(x => x.toString() === requiredOperation);

                // an operation that was required was not in the users list, they don't have permission
                if (!foundOperation || foundOperation.length === 0) {
                    return false;
                }
            } else {
                // the user has no operations so it must be that the need permission
                return false;
            }
        }

        // to reach this point means that all operations needed where found in the users operations
        return true;
    }

    public static HasAnyOperations(user?: AuthUser, requiredOperations?: string[]) {
        // if no operations are supplied, it is a public page
        if (!requiredOperations || requiredOperations.length === 0) {
            return true;
        }

        // if operations are supplied and the user is not logged in, they need to login
        if ((user === null || user === undefined) && requiredOperations.length > 0) {
            return false;
        }

        // search through all required operations to see if the user has all of them

        for (const requiredOperation of requiredOperations) {
            if (user && user.operations && user.operations.length > 0) {
                const foundOperation = user.operations.filter(x => x.toString() === requiredOperation);

                // an operation that was required was not in the users list, they don't have permission
                if (foundOperation && foundOperation.length > 0) {
                    return true;
                }
            } else {
                // the user has no operations so it must be that the need permission
                return false;
            }
        }

        // to reach this point means that all operations needed where found in the users operations
        return false;
    }

    public static RandomNumber(max) {
        return Math.floor(Math.random() * Math.floor(max));
    }

    public static getErrorMessage(error: SwaggerException) {
        if (error.response) {
            return JSON.parse(error.response).message;
        } else return undefined;
    }

    /**
     * Converts the given enum to a map of the keys to the values.
     * @param enumeration The enum to convert to a map.
     */
    public static enumToMap(enumeration: any): Map<any, any | any> {
        const map = new Map<string, string | number>();
        for (let key in enumeration) {
            //TypeScript does not allow enum keys to be numeric
            if (!isNaN(Number(key))) continue;

            const val = enumeration[key] as string | number;

            //TypeScript does not allow enum value to be null or undefined
            if (val !== undefined && val !== null) map.set(key, val);
        }
        return map;
    }

    public static CombineDateAndTime(date: Moment, time: Moment) {
        date = date.set({
            hour: time.get('hour'),
            minute: time.get('minute'),
            second: 0,
            millisecond: 0,
        });
        return date;
    }

    public static enumToSelectionOptionArray(enumeration: any, splitWords: boolean = false): { text: string; value: any }[] {
        const list: { text: string; value: any }[] = [];
        const func = splitWords ? startCase : (a) => a;
        this.enumToMap(enumeration).forEach((x, y) => list.push({ text: func(x), value: y }));
        return list;
    }

    /**
     * https://gist.github.com/nicbell/6081098#file-object-compare-js
     *
     * @static
     * @param {*} obj1
     * @param {*} obj2
     * @returns
     * @memberof Utility
     */
    public static Compare(obj1, obj2) {
        return isEqual(obj1, obj2);
        // Loop through properties in object 1
        // tslint:disable-next-line:forin
        for (let p in obj1) {
            // Check property exists on both objects
            if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) {
                return false;
            }

            switch (typeof obj1[p]) {
                // Deep compare objects
                case 'object':
                    if (!Utility.Compare(obj1[p], obj2[p])) {
                        return false;
                    }
                    break;
                // Compare function code
                case 'function':
                    if (
                        // tslint:disable-next-line:triple-equals
                        typeof obj2[p] === 'undefined' ||
                        // tslint:disable-next-line:triple-equals
                        (p !== 'compare' && obj1[p].toString() !== obj2[p].toString())
                    ) {
                        return false;
                    }
                    break;
                // Compare values
                default:
                    // tslint:disable-next-line:triple-equals
                    if (obj1[p] !== obj2[p]) {
                        return false;
                    }
            }
        }

        // Check object 2 for any extra properties
        for (let p in obj2) {
            if (!obj2.hasOwnProperty(p)) {
                continue;
            }
            if (!obj1.hasOwnProperty(p)) {
                return false;
            }
        }
        return true;
    }

}
