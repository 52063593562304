import React from 'react';
import AuthContext from '../../../Context/AuthContext';
import AuthUser from 'Core/Models/AuthUser';
import TokenService from 'Core/TokenService';
import ConfigService from 'Core/ConfigService';

export interface Props {
  children?: React.ReactNode;
}

export interface State {
  currentUser?: AuthUser;
  shouldRenderChildren?: boolean;
  userCheckInterval?: any;
}

export default class AuthContextProvider extends React.Component<Props, State> {
  update: (newUser: any) => void;

  constructor(props: Props) {
    super(props);

    this.state = { currentUser: undefined, shouldRenderChildren: false };
      this.updateUserBasedOnToken = this.updateUserBasedOnToken.bind(this);

    this.update = newUser => this.setState({ currentUser: newUser });
  }

  async componentDidMount() {
    window.addEventListener('load', this.updateUserBasedOnToken);
    const config = await ConfigService.get();
    const currentUser = TokenService.GetUserFromToken();

    //Peridically check if user data has changed
    const userCheckInterval = setInterval(
      this.updateUserBasedOnToken,
      config.Security.cookieCheckIntervalMs || 2000
    );
    this.setState({ currentUser, shouldRenderChildren: !!currentUser, userCheckInterval });
  }

  componentWillUnmount() {
    window.removeEventListener('load', this.updateUserBasedOnToken);
    clearInterval(this.state.userCheckInterval);
  }

  updateUserBasedOnToken(): AuthUser | undefined {
    const tokenUser = TokenService.GetUserFromToken();
    const currentUser = this.state.currentUser;

    if (!tokenUser && !currentUser) {
      this.setState({ shouldRenderChildren: true });
      return;
    } else if (tokenUser && !currentUser) {
      this.setState({ currentUser: tokenUser, shouldRenderChildren: true });
      return;
    } else if (!tokenUser?.expirationTime?.isSame(currentUser?.expirationTime)) {
      this.setState({ currentUser: tokenUser, shouldRenderChildren: true });
      return;
    }
  }

  render() {
    return (
      <AuthContext.Provider value={{ Update: this.update, data: this.state.currentUser }}>
        {this.state.shouldRenderChildren && this.props.children}
      </AuthContext.Provider>
    );
  }
}



