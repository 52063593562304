import React, { useContext } from "react";
import { Layout, Menu } from "antd";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { AppRoute } from "../../../Core/Models/AppRoute";
import AuthContext from "Context/AuthContext";
import Utility from "../../../Core/Utility";
import { PoweroffOutlined } from "@ant-design/icons"; // Import Poweroff icon

const { Sider } = Layout;

export interface AppMenuProps {
    menuList: AppRoute[];
    onlySubMenuNavigates?: boolean;
    defaultPosition: "top" | "side"; // Position of the NavBar
}

const AppMenu: React.FC<AppMenuProps> = ({ menuList, onlySubMenuNavigates, defaultPosition }) => {
    const { data: user } = useContext(AuthContext); // Access AuthContext and logout method
    const navigate = useNavigate();
    const location = useLocation();

    const getMenuItem = (menu: AppRoute, parentPath: string = "") => {
        const fullPath = Utility.combinePath(parentPath, menu.path || "");
        const hasPermission = Utility.HasAnyOperations(user, menu.requiredOperations || []);

        if (!hasPermission || menu.hideInMainMenu) {
            return null;
        }

        // Handle skipped items
        if (menu.skip && menu.routes?.length) {
            return menu.routes
                .map((subMenu) => getMenuItem(subMenu, fullPath))
                .filter(Boolean); // Only include valid child items
        }

        const handleClick = () => navigate(fullPath);

        // If the item has child routes, render as a SubMenu
        if (menu.routes?.length) {
            return {
                key: fullPath,
                label: menu.title,
                icon: menu.icon,
                children: menu.routes
                    .map((subMenu) => getMenuItem(subMenu, fullPath))
                    .filter(Boolean), // Filter out null/undefined items
            };
        }

        // Render a single menu item
        return {
            key: fullPath,
            label: (
                <NavLink to={fullPath} onClick={!onlySubMenuNavigates ? handleClick : undefined}>
                    {menu.title}
                </NavLink>
            ),
            icon: menu.icon,
        };
    };

    // Build the menu items list
    const menuItems = menuList
        .map((item) => getMenuItem(item))
        .flat() // Flatten the array to handle skipped children at the top level
        .filter(Boolean);

    // Add the logout item at the bottom
    const logoutMenuItem = {
        key: "logout",
        label: (
            <NavLink to='/logout'>
                <span style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    Logout
                </span>
            </NavLink>
        ),
        icon: <PoweroffOutlined />,
    };

    // Add logout item before collapse button
    menuItems.push(logoutMenuItem);

    return (
        <>
            {defaultPosition === "side" && (
                <Menu
                    theme="dark"
                    mode="inline"
                    selectedKeys={[location.pathname]}
                    items={[...menuItems]} // Spread to insert the logout item
                />
            )}
            {defaultPosition === "top" && (
                <Menu
                    theme="dark"
                    mode="horizontal"
                    selectedKeys={[location.pathname]}
                    items={[...menuItems]}
                />
            )}
        </>
    );
};

export default AppMenu;
