import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import AuthContext from 'Context/AuthContext';
import LogoutHandler from './LogoutHandler';

const Logout: React.FC = () => {
    const [isLogout, setIsLogout] = useState(false);
    const authContext = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const logoutHandler = new LogoutHandler(location);

        logoutHandler.RequesLogout().then(() => {
            authContext.Update(undefined);
            setIsLogout(true);
        });
    }, [authContext, location]);

    useEffect(() => {
        if (isLogout) {
            navigate('/login', {
                state: { from: location, noPermission: false },
            });
        }
    }, [isLogout, navigate, location]);

    return <div>{!isLogout ? 'Logging out...' : null}</div>;
};

export default Logout;
