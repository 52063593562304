import React, { useState } from "react";
import { Layout, Menu, Switch } from "antd";
import { Link, Outlet, useLocation } from "react-router-dom";
import { HeaderPageSection, MainLogo, PageContainer, SiderPageSection, StyledSider } from "../AdminLayout/AdminLayout.Styled";
import { AppRoutes } from '../../../Core/AppRoutes';
import mainLogo from "../../../Assests/images/NavBarLogo.png";

import { AppRoute } from "../../../Core/Models/AppRoute";
import AppMenu from "../AppMenu";

export interface Props {
    children?: React.ReactNode;
    menu: AppRoute[];
}

export interface Route {
    key: string;
    path: string;
    label: string;
    icon?: React.ReactNode; // Optional icon for the route
}



const { Header, Sider, Content } = Layout;

const AdminLayout: React.FC<Props> = ({ children, menu }) => {
    const [position, setPosition] = useState<"top" | "side">("side");
    const [collapsed, setCollapsed] = useState(false);
    const location = useLocation();

    // Toggle position between "top" and "side"
    const handlePositionChange = (checked: boolean) => {
        setPosition(checked ? "side" : "top");
    };

    return (
        <PageContainer>
                <div style={{ padding: "16px", textAlign: "right", right: '2px', position: "absolute" }}>
                    <Switch
                        checkedChildren="Side"
                        unCheckedChildren="Top"
                        onChange={handlePositionChange}
                        checked={position === "side"}
                    />
                </div>

                {position === "side" ? (
                    <Layout hasSider>
                        <StyledSider collapsible collapsed={collapsed} onCollapse={setCollapsed}>
                            <div
                                className="logo"
                                style={{
                                    textAlign: "center",
                                    margin: "16px 0",
                                }}
                            >
                                <MainLogo src={mainLogo} alt="Sudoku Logo" />
                            </div>
                        <AppMenu
                            menuList={AppRoutes}
                            defaultPosition={position}
                            />
                        </StyledSider>
                        <SiderPageSection>
                            <Content>
                                {children}
                            </Content>
                        </SiderPageSection>
                    </Layout>
                ) : (
                    <Layout>
                        <Header
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                            }}
                        >
                            <div
                                className="logo"
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <MainLogo src={mainLogo} alt="Sudoku Logo" />
                                &nbsp;
                                &nbsp;
                            </div>
                            <AppMenu
                                menuList={AppRoutes}
                                defaultPosition={position}
                            />
                        </Header>
                        <HeaderPageSection>
                            <Content>
                                {children}
                            </Content>
                        </HeaderPageSection>
                    </Layout>
                )}
        </PageContainer>
    );
};

export default AdminLayout;
