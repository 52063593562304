import React, { useState, useContext } from 'react';
import { Row, Col, Form, Input, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { LoginFormModel } from '../LoginForm/Models/LoginFormModel';
import { LoginFormHandler } from './LoginFormHandler';
import { StyledLoginForm } from '../../Layout/LoginLayout/LoginLayout.Styled'
import  AuthContext from '../../../Context/AuthContext';
import LoginForm from '../LoginForm';

const Login: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);

    const handleLogin = async (values: { username: string; password: string }) => {
        setLoading(true);
        try {
            const handler = new LoginFormHandler();
            const user = await handler.RequestLogin(values as LoginFormModel);

            if (user?.loggedIn) {
                authContext.Update(user); // Update context with logged-in user data
                navigate('/Admin/Dashboard'); // Redirect to dashboard
            } else {
                // Handle login failure (e.g., show error message)
                console.error('Login failed: ', user);
            }
        } catch (error) {
            console.error('An error occurred during login: ', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <StyledLoginForm>
            <div className="login-container">
                <Row justify="center" align="middle">
                    <Col span={8}>
                        <LoginForm
                            onLogin={handleLogin}
                        />
                    </Col>
                </Row>
            </div>
        </StyledLoginForm>
    );
};

export default Login;
