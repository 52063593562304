import styled from 'styled-components';
import { Card, Row, Col } from 'antd';
import Title from 'antd/es/typography/Title';

export const DashboardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background: linear-gradient(to bottom right, #1e3c72, #2a5298);
    min-height: 100vh;
    color: #ffffff;
`;

export const StyledTitle = styled(Title)`
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
`;

export const StyledRow = styled(Row)`
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    justify-content: center;
`;

export const StyledCard = styled(Card)`
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow-x: auto;

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 6px 16px rgba(0, 0, 0, 0.3);
    }

    .ant-card-body {
        padding: 16px;
    }

    h3 {
        color: #1e3c72;
        font-size: 1.25rem;
        margin-bottom: 8px;
    }

    p {
        color: #555555;
        font-size: 1rem;
    }
`;