
import { SecuredClient } from 'Core/Api/SecuredClient';

export class DashboardHandler {
    constructor(props?: any) { }

    async getDashboardData() {
        const client = new SecuredClient();
        client.showLoading = true;
        client.handleGeneralError = true;

        try {
            return await client.getAdminDashboard();
        } finally {
            client.showLoading = false;
        }
    }


}
