import React, { useEffect, useState } from 'react';
import { Col, Divider, Row, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import { DashboardWrapper, StyledRow, StyledCard, StyledTitle } from './Dashboard.Styled';
import { DashboardHandler } from './DashboardHandler';
import Title from 'antd/es/skeleton/Title';
import DashboardTable from '../Shared/DashboardTable/DashboardTable';

// Define the type for the card object
interface DashboardCard {
    title: string;
    subTitle: string;
    count: number | string;
    displayText: string;
}


export interface Props {
    children?: React.ReactNode;
}

const Dashboard: React.FC<Props> = () => {
    const navigate = useNavigate();
    const [playerCards, setPlayerCards] = useState<any>([]);
    const [gameCards, setGameCards] = useState<any>([]);
    const [gameCompletedCards, setGameCompletedCards] = useState<any>([]);

    const [topVSPlayers, setTopVSPlayers] = useState<any>([]);
    const [topEasyModePlayers, setTopEasyModePlayers] = useState<any>([]);
    const [topMediumModePlayers, setTopMediumModePlayers] = useState<any>([]);
    const [topHardModePlayers, setTopHardModePlayers] = useState<any>([]);
    const [topExpertModePlayers, setTopExpertModePlayers] = useState<any>([]);


    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await new DashboardHandler().getDashboardData();
                setPlayerCards(data.playerCards);
                setGameCards(data.gameCards);
                setGameCompletedCards(data.gameCompletedCards);

                setTopVSPlayers(data.topVSPlayers);
                setTopEasyModePlayers(data.topEasyModePlayers);
                setTopMediumModePlayers(data.topMediumModePlayers);
                setTopHardModePlayers(data.topHardModePlayers);
                setTopExpertModePlayers(data.topExpertModePlayers);
            } catch (error) {
                console.error('Error fetching dashboard data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const vsLeaderboardColumns = [
        { title: 'Position', dataIndex: 'position', key: 'position' },
        { title: 'Username', dataIndex: 'userName', key: 'userName' },
        { title: 'Rank', dataIndex: 'rank', key: 'rank' },
        { title: 'Rating', dataIndex: 'rating', key: 'rating' },
        { title: 'Wins', dataIndex: 'wins', key: 'wins' },
        { title: 'Losses', dataIndex: 'losses', key: 'losses' },
    ];

    const practiceLeaderboardColumns = [
        { title: 'Position', dataIndex: 'position', key: 'position' },
        { title: 'Username', dataIndex: 'userName', key: 'userName' },
        { title: 'Rank', dataIndex: 'rank', key: 'rank' },
        { title: 'BestTime', dataIndex: 'bestTime', key: 'bestTime' },
    ];


    if (loading) {
        return <p>Loading dashboard...</p>;
    }



    return (
        <DashboardWrapper>
            <StyledTitle>Dashboard</StyledTitle>

            {/* Dashboard Cards */}
            <StyledTitle level={2}>Player Data</StyledTitle>
            <StyledRow gutter={[16, 16]}>
                {playerCards?.map((card: DashboardCard, index: number) => (
                    <Col key={index} xs={24} sm={12} lg={8}>
                        <StyledCard>
                            <h3>{card.title}</h3>
                            <p>{card.subTitle}</p>
                            <p>
                                {card.count} {card.displayText.toLowerCase()}
                            </p>
                        </StyledCard>
                    </Col>
                ))}
            </StyledRow>
            <StyledRow gutter={[16, 16]}>
                <Divider />
            </StyledRow>
            <StyledTitle level={2}>Game Data</StyledTitle>
            <StyledRow gutter={[16, 16]}>
                {gameCards?.map((card: DashboardCard, index: number) => (
                    <Col key={index} xs={24} sm={12} lg={8}>
                        <StyledCard>
                            <h3>{card.title}</h3>
                            <p>{card.subTitle}</p>
                            <p>
                                {card.count} {card.displayText.toLowerCase()}
                            </p>
                        </StyledCard>
                    </Col>
                ))}
            </StyledRow>
            <StyledRow gutter={[16, 16]}>
                <Divider />
            </StyledRow>
            <StyledTitle level={2}>Completed Game Data</StyledTitle>
            <StyledRow gutter={[16, 16]}>
                {gameCompletedCards?.map((card: DashboardCard, index: number) => (
                    <Col key={index} xs={24} sm={12} lg={8}>
                        <StyledCard>
                            <h3>{card.title}</h3>
                            <p>{card.subTitle}</p>
                            <p>
                                {card.count} {card.displayText.toLowerCase()}
                            </p>
                        </StyledCard>
                    </Col>
                ))}
            </StyledRow>

            {/* Leaderboard Tables */}
            <StyledTitle level={2}>Leaderboards</StyledTitle>

            <Row gutter={[16, 16]}>
                {/* Top VS Players */}
                <Col span={24}>
                    <StyledCard title="Top VS Mode Players">
                        <DashboardTable
                            dataSource={topVSPlayers}
                            columns={vsLeaderboardColumns}
                            rowKey="username"
                        />
                    </StyledCard>
                </Col>

                <Col md={{ span:12 }} xs={{ span: 24 }}>
                    <StyledCard title="Top Expert Mode Players">
                        <DashboardTable
                            dataSource={topEasyModePlayers}
                            columns={practiceLeaderboardColumns}
                            rowKey="username"
                        />
                    </StyledCard>
                </Col>

                <Col md={{ span: 12 }} xs={{ span: 24 }}>
                    <StyledCard title="Top Medium Mode Players">
                        <DashboardTable
                            dataSource={topMediumModePlayers}
                            columns={practiceLeaderboardColumns}
                            rowKey="username"
                        />
                    </StyledCard>
                </Col>

                <Col md={{ span: 12 }} xs={{ span: 24 }}>
                    <StyledCard title="Top Hard Mode Players">
                        <DashboardTable
                            dataSource={topHardModePlayers}
                            columns={practiceLeaderboardColumns}
                            rowKey="username"
                        />
                    </StyledCard>
                </Col>

                <Col md={{ span: 12 }} xs={{ span: 24 }}>
                    <StyledCard title="Top Expert Mode Players">
                        <DashboardTable
                            dataSource={topExpertModePlayers}
                            columns={practiceLeaderboardColumns}
                            rowKey="username"
                        />
                    </StyledCard>
                </Col>
            </Row>
        </DashboardWrapper>
    );
};

export default Dashboard;
