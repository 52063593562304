import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { message } from 'antd';
import React from 'react';
import NotificationService from '../NotificationService';

export interface ErrorDetails {
    statusCode?: number;
    message?: string;
    eventMarker?: string;
}

export interface ExtendedAxios extends AxiosInstance {
    handleSecurityError?: boolean;
    showLoading?: boolean;
    handleGeneralError?: boolean;
}

const getSecuredAxiosInstance = (): ExtendedAxios => {
    const SecuredAxios: ExtendedAxios = Object.assign(
        { handleSecurityError: true },
        axios.create({
            timeout: 30000000,
        })
    );

    SecuredAxios.interceptors.request.use(
        (config) => {
            // turning on loader
            ShowLoader(config);

            // Retrieve token from session storage
            const token = sessionStorage.getItem('token');

            // Add Authorization header only if token is not null
            if (token) {
                config.headers.authorization = `Bearer ${token}`;
            }

            return config;
        },
        (error) => {
            // Do something with error
            return Promise.reject(error);
        }
    );

    SecuredAxios.interceptors.response.use(
        (response) => {
            if (SecuredAxios.showLoading) {
                NotificationService.hideLoader();
            }
            return response;
        },
        (error: { config: AxiosRequestConfig; response: AxiosResponse }) => {
            HideAllLoaders();
            HandleSecurityErrors(error);
            HandleErrors(error);
            return Promise.reject(error);
        }
    );

    const HideAllLoaders = () => {
        if (SecuredAxios.showLoading) {
            NotificationService.hideLoader();
        }
    };

    const HandleSecurityErrors = (error: { config: AxiosRequestConfig; response: AxiosResponse<any> }) => {
        if (SecuredAxios.handleSecurityError) {
            const errorDetails = GetErrorDetails(error);
            if (errorDetails.statusCode === 403) {
                NotificationService.error(
                    'You do not have permission to access that operation. You can try logging in with credentials that do.',
                    undefined,
                    10000
                );
            }
            if (errorDetails.statusCode === 401) {
                window.location.href = '/login';
                NotificationService.error(
                    'You were inactive for a long time and need to log in again. Please log in to continue.',
                    undefined,
                    10000
                );
            }
        }
    };

    const HandleErrors = (error: { config: AxiosRequestConfig; response: AxiosResponse<any> }) => {
        if (SecuredAxios.handleGeneralError) {
            const errorDetails = GetErrorDetails(error);
            if (errorDetails.statusCode === 400) {
                NotificationService.error(errorDetails.message, undefined, 10000);
            } else if (errorDetails.statusCode === 800) {
                NotificationService.error(
                    'Had a problem making that last request, please ensure you are connected to the internet.',
                    undefined,
                    10000
                );
            } else if (errorDetails.statusCode === 403 || errorDetails.statusCode === 401) {
                // It is a security error and will be handled elsewhere
            } else {
                NotificationService.error(
                    'Oops...Something Went Wrong',
                    <>
                        <p>Something happened on that last request. Please contact the administrator with this error code:</p>
                        <p>{errorDetails.eventMarker}</p>
                    </>,
                    0,
                    true
                );
            }
        }
    };

    const GetErrorDetails = (error: any): ErrorDetails => {
        return (error.response || { data: { statusCode: 800, eventMarker: '1111-1111-1111-1111' } }).data;
    };

    const ShowLoader = (config: any) => {
        if (SecuredAxios.showLoading) {
            NotificationService.showLoader();
        }
    };

    return SecuredAxios;
};

export default getSecuredAxiosInstance;
