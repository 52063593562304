import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import EmptyLayout from '../Layout/EmptyLayout';
import { RoutesRenderer } from '../Layout/MainContent';
import './App.css';
import '../../Assests/styles/react-transitions.css';
import AuthContextProvider from '../Auth/AuthContextProvider';

function App() {
    return (
        <div className="App">
            <AuthContextProvider>
                <BrowserRouter>
                    <div className="transition-container">
                        <RoutesRenderer defaultLayout={EmptyLayout} />
                    </div>
                </BrowserRouter>
            </AuthContextProvider>
        </div>
    );
}

export default App;
