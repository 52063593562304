import { LoginFormModel } from '../LoginForm/Models/LoginFormModel';
import { SecuredClient } from '../../../Core/Api/SecuredClient';
import { AdminLoginCommandResponse, AdminLoginCommandRequest } from '../../../Core/Api/Api';

export class LoginFormHandler {
    async RequestLogin(loginFormModel: LoginFormModel): Promise<AdminLoginCommandResponse | undefined> {
        const client = new SecuredClient();
        const request = new AdminLoginCommandRequest();
        request.init(loginFormModel);

        client.showLoading = true;
        client.handleGeneralError = true;

        try {
            const response = await client.adminLogin(request);

            if (response?.accessToken && response.loggedIn) {
                sessionStorage.setItem('token', response.accessToken); // Store access token
                sessionStorage.setItem('user', JSON.stringify(response)); // Store user data
                console.log(JSON.stringify(response.operations))
            }

            return response;
        } catch (error) {
            console.error('Login request failed: ', error);
            throw error; // Allow caller to handle the error
        }
    }
}
