import * as React from 'react';
import { Button, Col, Form, Input } from 'antd';
import { LoginOutlined, GoogleOutlined } from '@ant-design/icons';

interface LoginFormProps {
    onLogin: (values: { username: string; password: string }) => void;
}

const LoginForm: React.FC<LoginFormProps> = ({ onLogin }) => {
    return (
        <Form
            name="login"
            initialValues={{ remember: true }}
            onFinish={onLogin}
            autoComplete="off"
            id="Login-Form"
        >
            <Form.Item
                name="username"
                rules={[{ required: true, message: 'Please input your username!' }]}
            >
                <Input placeholder="Username/Email" />
            </Form.Item>

            <Form.Item
                name="password"
                rules={[{ required: true, message: 'Please input your password!' }]}
            >
                <Input.Password placeholder="Password" />
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit" block>
                    <div className="button-content">
                        <LoginOutlined className="button-icon" />
                        <span className="button-text">Login</span>
                    </div>
                </Button>
            </Form.Item>
        </Form>
    );
};

export default LoginForm;
