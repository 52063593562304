// StyledTable.tsx
import React from 'react';
import { Table, TablePaginationConfig } from 'antd';
import styled from 'styled-components';

interface DashboardTableProps {
    dataSource: any[];
    columns: any[];
    rowKey: string;
}

const TableWrapper = styled.div`

`;

const DashboardTable: React.FC<DashboardTableProps> = ({ dataSource, columns, rowKey }) => {
    return (
        //<TableWrapper>
            <Table
                dataSource={dataSource}
                columns={columns}
                rowKey={rowKey}
                pagination={false}
            />
        //</TableWrapper>
    );
};

export default DashboardTable;
