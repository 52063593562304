import styled from 'styled-components';
import { Layout } from 'antd';

const { Content } = Layout;

export const StyledLoginForm = styled(Content)`
.login-container {
    padding: 50px;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.sudoku-Nav-button-container {
    margin-bottom: 16px;
}
.button-content {
    display: flex;
    align-items: center;
}

.button-icon {
    margin-right: 8px;
}

.button-text {
    font-weight: bold;
}

`;

export const MainLogo = styled.img`
  max-height: 50vh;
  width: auto;
  display: block;
  margin-right: 2em; /* Space between the logo and the buttons */
  object-fit: contain; /* Ensures the image scales correctly */
`;
